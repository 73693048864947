import { useCallback, useId } from "react"

import { createDesignSystemQueryKey } from "@supernovaio/cloud/hooks/data/useDesignSystem"
import { useSafeQuery } from "@supernovaio/cloud/hooks/useSafeQuery"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { useQueryClient } from "@tanstack/react-query"

type QueryIds = {
  dsId: string
}

const createQueryKey = (ids: QueryIds) => [
  ...createDesignSystemQueryKey({
    dsId: ids.dsId,
  }),
  "designSystemContacts",
]

export const useInvalidateDesignSystemContacts = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (ids: QueryIds) =>
      queryClient.invalidateQueries({
        queryKey: createQueryKey(ids),
      }),

    [queryClient]
  )
}

/**
 * Fetches design system contacts.
 * This hook should be used when the current user doesn't have access to the list of all members.
 * @param dsId Design system ID.
 */
export const useDesignSystemContacts = (dsId: string | undefined) => {
  const fallbackDsId = useId()

  return useSafeQuery({
    queryKey: createQueryKey({
      dsId: dsId || fallbackDsId,
    }),
    queryFn: async () => {
      if (!dsId) {
        return null
      }

      const sdk = await getClientSdk()
      return sdk.designSystems.designSystemContactList(dsId)
    },
  })
}
