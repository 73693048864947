"use client"

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { useRouter } from "next/navigation"

import { useDesignSystemContacts } from "@supernovaio/cloud/hooks/data/useDesignSystemContacts"
import { SuspendedSubscriptionDialog } from "@supernovaio/cloud/ui/dialogs/SuspendedSubscriptionDialog"
import { isFeatureForbiddenBySuspended } from "@supernovaio/cloud/utils/productUtils"
import {
  ProductFeature,
  WorkspaceSubscriptionProductCode,
} from "@supernovaio/sdk"

import { AclOperations } from "@supernova-studio/acl"

import { useSubscriptionProductFeatures } from "../hooks/data/useSubscriptionProductFeatures"
import { useWorkspaceAvailableProducts } from "../hooks/data/useWorkspaceAvailableProducts"
import { useWorkspaceSubscription } from "../hooks/data/useWorkspaceSubscription"
import { useSelectPlanRoute } from "../hooks/routing/useSelectPlanRoute"
import { getClosestEligiblePlan } from "../hooks/useClosestEligiblePlan"
import { useParams } from "../hooks/useParams"
import { usePermissions } from "../hooks/usePermissions"
import {
  PromoteUpgradeDialog,
  PromoteUpgradeDialogProps,
} from "../ui/dialogs/PromoteUpgradeDialog"
import { useCreateSubscription } from "../ui/settings/hooks/subscription/useCreateSubscription"
import { useUpdateSubscription } from "../ui/settings/hooks/subscription/useUpdateSubscription"
import { CONTACT_SALES } from "../utils/links"

interface PromoteUpgradeDialogContextInterface {
  shouldOpenUpgradeDialog: (props: OpenDialogProps) => boolean
  forceOpenUpgradeDialog: (props: ForceOpenDialogProps) => void
}

type OmitedProductFeature = Omit<
  ProductFeature[number],
  "errorMessage" | "errorReason"
>

export type OpenDialogProps = {
  feature: string
  valueSearcher?: {
    param: keyof OmitedProductFeature
    value: OmitedProductFeature[keyof OmitedProductFeature]
  }
} & Pick<
  PromoteUpgradeDialogProps,
  "title" | "description" | "shouldDisplayFeatures"
>

export type ForceOpenDialogProps = {
  targetPlan: WorkspaceSubscriptionProductCode
  /** If undefined then this will just update the plan without touching the number of seats */
  newSeatCount?: number
} & Pick<OpenDialogProps, "title" | "description">

export const PromoteUpgradeDialogContext =
  createContext<PromoteUpgradeDialogContextInterface | null>(null)

export const usePromoteUpgradeDialog = () => {
  const context = useContext(PromoteUpgradeDialogContext)

  if (!context) {
    throw new Error(
      "usePromoteUpgradeDialog must be used within a PromoteUpgradeDialogProvider"
    )
  }

  return context
}

function PromoteUpgradeDialogProvider({ children }: PropsWithChildren) {
  const router = useRouter()
  const { wsId, dsId } = useParams()
  const { data: subscription } = useWorkspaceSubscription({ workspaceId: wsId })
  const { hasPermissionNew } = usePermissions(wsId, dsId)
  const { data: contacts } = useDesignSystemContacts(dsId)
  const { data: productFeatures } = useSubscriptionProductFeatures(wsId)
  const {
    handleCreateSubscription: createSubscription,
    isPending: isPendingCreate,
  } = useCreateSubscription()
  const {
    handleUpdateSubscription: updateSubscription,
    isPending: isPendingUpdate,
  } = useUpdateSubscription()

  const { data: products } = useWorkspaceAvailableProducts({
    workspaceId: wsId,
  })

  const hasUpgradePermission = hasPermissionNew(
    AclOperations.WorkspaceSubscriptionUpdate
  )

  const [targetPlan, setTargetPlan] =
    useState<WorkspaceSubscriptionProductCode | null>(null)
  const [newSeatCount, setNewSeatCount] = useState<number | undefined>(
    undefined
  )

  const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined)
  const [dialogDescription, setDialogDescription] = useState<
    React.ReactNode | undefined
  >(undefined)
  const [displayFeatureLines, setDisplayFeatureLines] = useState(true)
  const [isSuspendedDialogOpened, setIsSuspendedDialogOpened] = useState(false)

  const [isRedirecting, setIsRedirecting] = useState(false)

  const productInfo = products?.find((product) => product.code === targetPlan)

  const selectPlanRoute = useSelectPlanRoute(newSeatCount)

  const isLoading = isPendingCreate || isPendingUpdate || isRedirecting

  const shouldOpenUpgradeDialog = useCallback(
    ({
      feature,
      valueSearcher = {
        param: "enabled",
        value: true,
      },
      title,
      description,
      shouldDisplayFeatures = true,
    }: OpenDialogProps): boolean => {
      if (subscription) {
        const { status, featuresSummary } = subscription
        const featureValue = featuresSummary[feature]?.[valueSearcher.param]
        if (
          isFeatureForbiddenBySuspended({
            status,
            valueSearcher,
            featureValue,
          })
        ) {
          setIsSuspendedDialogOpened(true)
          return true
        }
      }

      const closestPlan = getClosestEligiblePlan({
        feature,
        productFeatures,
        subscription,
        valueSearcher,
      })

      if (!closestPlan) {
        return false
      }

      setTargetPlan(closestPlan)
      setDialogTitle(title)
      setDialogDescription(description)
      setDisplayFeatureLines(shouldDisplayFeatures)
      return true
    },
    [productFeatures, subscription]
  )

  const forceOpenUpgradeDialog = useCallback(
    ({
      targetPlan: innerTargetPlan,
      newSeatCount: innerSeatCount,
      title,
      description,
    }: ForceOpenDialogProps) => {
      setTargetPlan(innerTargetPlan)
      setNewSeatCount(innerSeatCount)
      setDialogTitle(title)
      setDialogDescription(description)
    },
    []
  )

  const contextValue = useMemo(
    () => ({
      shouldOpenUpgradeDialog,
      forceOpenUpgradeDialog,
    }),
    [shouldOpenUpgradeDialog, forceOpenUpgradeDialog]
  )

  const handleConfirmationButton = async (stripePriceId: string) => {
    if (!contacts || !productInfo || isLoading) {
      setTargetPlan(null)
      return
    }

    const ownerEmails = contacts.getWorkspaceEmails().join(",")

    if (!hasUpgradePermission) {
      setTargetPlan(null)
      window.open(`mailto:${ownerEmails}`, "_blank")
      return
    }

    if (
      productInfo?.code === WorkspaceSubscriptionProductCode.enterprise ||
      !subscription?.rawRemoteSubscription.isPricePerCreator
    ) {
      window.open(CONTACT_SALES, "_blank")
      setTargetPlan(null)
      return
    }

    let response
    if (
      subscription.rawRemoteSubscription.product ===
      WorkspaceSubscriptionProductCode.free
    ) {
      response = await createSubscription({
        priceId: stripePriceId,
        seatCount: newSeatCount,
      })
    } else {
      response = await updateSubscription({
        newPriceId: stripePriceId,
        newSeatLimit: newSeatCount,
      })
    }
    if (response) {
      setIsRedirecting(true)
      router.push(response.redirectUrl)
    }
  }

  return (
    <PromoteUpgradeDialogContext.Provider value={contextValue}>
      {children}
      {productInfo && subscription && (
        <PromoteUpgradeDialog
          description={dialogDescription}
          hasUpgradePermission={hasUpgradePermission}
          isLoading={isLoading}
          isOpen={Boolean(targetPlan)}
          selectPlanRoute={selectPlanRoute ?? "#"}
          subscription={subscription.rawRemoteSubscription}
          targetProduct={productInfo}
          title={dialogTitle}
          onCancel={() => setTargetPlan(null)}
          onConfirm={handleConfirmationButton}
          shouldDisplayFeatures={displayFeatureLines}
        />
      )}
      {contacts && subscription && (
        <SuspendedSubscriptionDialog
          isOpen={isSuspendedDialogOpened}
          isLoading={isLoading}
          subscription={subscription.rawRemoteSubscription}
          ownerEmails={contacts.getWorkspaceEmails()}
          hasUpgradePermission={hasUpgradePermission}
          onClose={() => setIsSuspendedDialogOpened(false)}
        />
      )}
    </PromoteUpgradeDialogContext.Provider>
  )
}

export default PromoteUpgradeDialogProvider
