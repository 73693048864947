import { useMemo, useState } from "react"

import { useTranslations } from "next-intl"
import { useRouter } from "next/navigation"

import { DMConfirmationDialog } from "@supernovaio/dm"

import { useUpdateSubscription } from "@supernovaio/cloud/ui/settings/hooks/subscription/useUpdateSubscription"
import { CONTACT_SALES } from "@supernovaio/cloud/utils/links"
import { WorkspaceSubscription } from "@supernovaio/sdk"

type Props = {
  isOpen: boolean
  isLoading?: boolean
  subscription: WorkspaceSubscription
  hasUpgradePermission: boolean
  ownerEmails: Array<string>
  title?: string
  description?: string
  onClose: () => void
}

export function SuspendedSubscriptionDialog({
  isOpen,
  isLoading = false,
  subscription,
  hasUpgradePermission,
  ownerEmails,
  title,
  description,
  onClose,
}: Props) {
  const t = useTranslations("SuspendedSubscriptionDialog")
  const {
    handleUpdateSubscription: updateSubscription,
    isPending: isPendingUpdate,
  } = useUpdateSubscription()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const router = useRouter()

  const ctaProps = useMemo(() => {
    if (hasUpgradePermission) {
      const canSelfUpdate = subscription.isPricePerCreator
      if (canSelfUpdate) {
        return {
          title: t("Buttons.updatePaymentMethod"),
          action: async () => {
            const response = await updateSubscription()
            if (response) {
              setIsRedirecting(true)
              router.push(response.redirectUrl)
            }
          },
        }
      }
      return {
        title: t("Buttons.contactUs"),
        action: () => {
          window.open(CONTACT_SALES, "_blank")
          onClose()
        },
      }
    }
    return {
      title: t("Buttons.contactAdmin"),
      action: () => {
        window.open(`mailto:${ownerEmails.join(",")}`, "_blank")
        onClose()
      },
    }
  }, [
    hasUpgradePermission,
    onClose,
    ownerEmails,
    router,
    subscription.isPricePerCreator,
    t,
    updateSubscription,
  ])

  return (
    <DMConfirmationDialog
      open={isOpen}
      onOpenChange={onClose}
      title={title || t("title")}
      description={description || t("description")}
      isLoading={isLoading || isPendingUpdate || isRedirecting}
      buttonLabelConfirm={ctaProps.title}
      onConfirm={ctaProps.action}
      buttonLabelCancel={t("Buttons.cancel")}
      onCancel={onClose}
    />
  )
}
