"use client"

import { useEffect } from "react"

import { useSegment } from "@supernovaio/cloud/features/segment/SegmentProvider"
import { useWorkspaceSubscription } from "@supernovaio/cloud/hooks/data/useWorkspaceSubscription"
import { useCurrentMembership } from "@supernovaio/cloud/hooks/useCurrentMembership"
import { useParams } from "@supernovaio/cloud/hooks/useParams"

export function SegmentGroupManager() {
  const { group } = useSegment()
  const { wsId } = useParams()
  const { membership, isPending: isMembershipPending } = useCurrentMembership({
    wsId,
  })
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useWorkspaceSubscription({ workspaceId: wsId })

  const workspace = membership?.workspace

  const workspaceId = workspace?.id
  const workspaceName = workspace?.profile.name
  const productCode = subscription?.product
  const productInterval = subscription?.planInterval
  const avatar = workspace?.profile.avatar

  const isLoading = isMembershipPending || isSubscriptionLoading

  useEffect(() => {
    ;(async () => {
      if (!isLoading) {
        await group({
          workspaceId: workspaceId || null,
          name: workspaceName,
          avatar: avatar || undefined,
          productCode,
          productInterval,
          onboardingCompanyName: undefined,
          onboardingCompanySize: undefined,
          onboardingDesignSystemName: undefined,
          onboardingDesignTeamSize: undefined,
        })
      }
    })()
  }, [
    workspaceId,
    isLoading,
    group,
    workspaceName,
    productCode,
    productInterval,
    avatar,
  ])

  return null
}
