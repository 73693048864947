"use client"

import { useEffect } from "react"

import { useSegment } from "@supernovaio/cloud/features/segment/SegmentProvider"
import { useMe } from "@supernovaio/cloud/hooks/data/useMe"
import { useMemberships } from "@supernovaio/cloud/hooks/data/useMemberships"
import { useWorkspaceSubscription } from "@supernovaio/cloud/hooks/data/useWorkspaceSubscription"

export function SegmentOnboardingGroupManager() {
  const { group } = useSegment()
  const { data: me, isLoading: isUserLoading } = useMe()
  const { data: memberships, isLoading: areMembershipsLoading } =
    useMemberships(me?.id)

  // During onboarding, set the group only when it's possible to determine the workspace: freshly created/invited to
  const workspace = memberships?.length === 1 ? memberships[0]?.workspace : null
  const workspaceId = workspace?.id

  const { data: subscription, isLoading: isSubscriptionLoading } =
    useWorkspaceSubscription({ workspaceId })

  const workspaceName = workspace?.profile.name
  const productCode = subscription?.product
  const productInterval = subscription?.planInterval
  const onboardingCompanyName = me?.profile.onboarding?.companyName || undefined
  const onboardingCompanySize =
    me?.profile.onboarding?.numberOfPeopleInOrg || undefined
  const onboardingDesignSystemName =
    me?.profile.onboarding?.designSystemName || undefined
  const onboardingDesignTeamSize =
    me?.profile.onboarding?.numberOfPeopleInDesignTeam || undefined

  const isLoading =
    isUserLoading || areMembershipsLoading || isSubscriptionLoading

  useEffect(() => {
    ;(async () => {
      if (!isLoading && workspaceId) {
        await group({
          workspaceId,
          name: workspaceName,
          avatar: undefined,
          productCode,
          productInterval,
          onboardingCompanyName,
          onboardingCompanySize,
          onboardingDesignSystemName,
          onboardingDesignTeamSize,
        })
      }
    })()
  }, [
    workspaceId,
    isLoading,
    group,
    workspaceName,
    productCode,
    productInterval,
    onboardingCompanyName,
    onboardingCompanySize,
    onboardingDesignSystemName,
    onboardingDesignTeamSize,
  ])

  return null
}
